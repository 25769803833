import queryString from 'query-string';
import { BaseService } from '../base.service';
import store from '@/store';
import { ResponseWrapper, ErrorWrapper } from '../util';

export class OfficeService extends BaseService {
  static get entity() {
    return 'office';
  }
  static async getAll({ currentPage, rowsPerPage, ...query }) {
    currentPage = currentPage || 1;
    rowsPerPage = rowsPerPage || 35;
    try {
      let response = await this.get(`offices?${queryString.stringify({ 
        //MAX-1129
        //limit: `${(currentPage - 1) * rowsPerPage},${rowsPerPage}`,
        sort: 'name',
        ...query, 
      })}`);
      return response;
    } catch (error) {
      
      throw error;
    }
  }

  static async getRegionsDropdown(query = {}) {
    try {
      let response = await this.get(`regions/tagsDropdownOptGroup?${queryString.stringify({
        sort: 'entry_value',
      })}`);
      return response;
    } catch (error) {
      
      throw error;
    }
  }

  // static async getStatesDropdown(query = {}) {
  //   try {
  //     let response = await this.get(`offices?${queryString.stringify({
  //       sort: 'name',
  //     })}`);
  //     return response;
  //   } catch (error) {
  //     
  //     throw error;
  //   }
  // }

  static async getOffice(id) {
    console.log('in the service, getting office data...');
    try {
      if (typeof id === 'object') {
        const response = await this.get(`${this.entity}/${id.office_id}`);
        return response;
      } else {
        const response = await this.get(`${this.entity}/${id}`);
        // if (response?.data?.result) {
        //   let ytd = await store.dispatch('cube/getOfficeYTDGCI', {
        //     officeID: response.data.result.ID
        //   });
        //   if (ytd) {
        //     response.data.result.gciSumYear = Number(ytd['PerformanceCube.gciForPeriodEur']);
        //   }
        // }
        return response;
      }
    } catch (error) {
      
      throw error;
    }
  }

  static async getOfficeExtendedData(id) {
    try {
      //https://api.staging.remaxeu-datahub.bwscloud.tech/v1/office/getInfo?officeID=10550

      const response = await this.get(`v1/office/getInfo?officeID=${id}`);
      return response;

    } catch (error) {
      
      throw error;
    }
  }

  static async getOfficeStatistics(id) {
    try {
      if (typeof id === 'object') {
        const response = await this.get(`${this.entity}/${id.office_id}/statistics`);
        return response;
      } else {
        const response = await this.get(`${this.entity}/${id}`);
        return response;
      }
    } catch (error) {
      
      throw error;
    }
  }
  //Notes
  static async getNoteList(id) {
    try {
      const response = await this.get(`${this.entity}/${id}/note`);
      return response;
    } catch (error) {
      
      throw error;
    }
  }

  static async insertNote(entity, id) {
    try {
      const response = await this.post(`${this.entity}/${id}/note`, entity);
      return response;
    } catch (error) {
      
      throw error;
    }
  }

  static async insertStaff(entity, id) {
    try {
      const response = await this.post(`${this.entity}/${id}/staff`, entity);
      return response;
    } catch (error) {
      
      throw error;
    }
  }

  static async insertAgent(entity, id) {
    try {
      const response = await this.post(`${this.entity}/${id}/agent`, entity);
      return response;
    } catch (error) {
      
      throw error;
    }
  }

  static async updateNote(entity, id) {
    try {
      const response = await this.put(`note/${id}`, entity);
      return response;
    } catch (error) {
      
      throw error;
    }
  }

  static async deleteNote(id) {
    try {
      const response = await this.delete(`note/${id}`);
      return response;
    } catch (error) {
      
      throw error;
    }
  }

  //Change History
  static async getOfficeHistory(id) {
    try {
      const response = await this.get(`${this.entity}/${id}/history`);
      return response;
    } catch (error) {
      
      throw error;
    }
  }

  static async selectOfficeContactInformationList(office_id) {
    try {
      let response = await this.get(`${this.entity}/${office_id}/contactInformationEntry`);
      return response;
    } catch (error) {
      
      throw error;
    }
  }

  static async updateOffice(office_id, data) {
    try {
      let response = await this.put(`${this.entity}/${office_id}`, data);
      return response;
    } catch (error) {
      
      throw error;
    }
  }

  static async getGroupsInRegion(office_id) {
    try {
      let response = await this.get(`${this.entity}/${office_id}/groupsInRegion`);
      return response;
    } catch (error) {
      
      throw error;
    }
  }

  static async getSnapshotDropdown(office_id) {
    try {
      let response = await this.get(`${this.entity}/${office_id}/snapshots`);
      return response;
    } catch (error) {
      
      throw error;
    }
  }

  static async getOfficeShareSnapshotData(snapshot_id) {
    try {
      let response = await this.get(`officeShareSnapshot/${snapshot_id}`);
      return response;
    } catch (error) {
      
      throw error;
    }
  }

  static async getMainOffices(office_id) {
    try {
      let response = await this.get(`${this.entity}/${office_id}`);
      return response;
    } catch (error) {
      
      throw error;
    }
  }

  static async getOfficeRegionFilters() {
    try {
      let response = await this.get(`regions`);
      return response;
    } catch (error) {
      
      throw error;
    }
  }

  static async deleteOfficeFromGroup(params = {}) {
    try {
      let response = await this.delete(`remaxEntityHasGroup/${params.id}`, params);
      return response;
    } catch (error) {
      
      throw error;
    }
  }

  static async updateOfficeGroup(params = {}) {
    try {
      let response = await this.put(`remaxEntityHasGroup/${params.id}`, params);
      return response;
    } catch (error) {
      
      throw error;
    }
  }

  static async addOfficeToGroup(params = {}) {
    try {
      let response = await this.post(`remaxEntityHasGroups`, params);
      return response;
    } catch (error) {
      
      throw error;
    }
  }

  static async getOfficeGroups(params = {}) {
    try {
      let response = await this.get(`remaxEntityHasGroup/${params.entityID}/groups?only_active=${params.onlyActive ? 1 : 0}`);
      return response;
    } catch (error) {
      
      throw error;
    }
  }

  static async officeShareSnapshotStep1Validation(params = {}) {
    try {
      let response = await this.post(`officeShareSnapshot`, params);
      return response;
    } catch (error) {
      
      throw error;
    }
  }

  static async officeShareSnapshotStep2Validation(params = {}) {
    try {
      // let response = await this.put(`officeShareSnapshot/${params.ID}`, params);
      let response = await this.post(`officeShareSnapshot/stepTwoConfirm`, params);
      return response;
    } catch (error) {
      
      throw error;
    }
  }

  static async getDevelopmentSchedules(id) {
    try {
      let response = await this.get(`${this.entity}/${id}/developmentSchedule`);

      return response;
    } catch (error) {
      
      throw error;
    }
  }
  static async getDevelopmentSchedulesBySnapshot(id, snapshotID) {
    try {
      let response = await this.get(`${this.entity}/${id}/developmentSchedule?snapshotID=${snapshotID}`);

      return response;
    } catch (error) {
      
      throw error;
    }
  }

  static async getDevelopmentSchedulesBySnapshot2(id, snapshotID) {
    try {
      let response = await this.get(`${this.entity}/${id}/developmentSchedule?snapshotID=${snapshotID}`);

      return response;
    } catch (error) {
      
      throw error;
    }
  }

  static async insertDevelopmentSchedules(id, payload) {
    try {
      payload.forEach((e) => {
        var cleanDate = e.term_start_date.replace(/\./g, '-');
        const splitDates = cleanDate.split('-');
        const day = splitDates[0];
        const month = splitDates[1];
        const year = splitDates[2];
        e.planGCI = e.plan_gci;
        e.planNumAssociates = e.plan_num_associates;
        e.termStartDate = year + '-' + month + '-' + day;
      });
      payload = {
        officeDevelopmentSchedules: payload,
        officeID: id,
        contractID: payload[0].contract_id,
        validFrom: payload[0].valid_from,
      };
      let response = await this.put(
        `officeDevelopmentScheduleSnapshot/${payload.contractID}/officeDevelopmentSchedules`,
        payload
      );
      return response;
    } catch (error) {
      
      throw error;
    }
  }
  static async getDevelopmentSchedulesSnapshot(id) {
    try {
      let response = await this.get(`${this.entity}/${id}/developmentScheduleSnapshots`);
      return response;
    } catch (error) {
      
      throw error;
    }
  }
  static async getFranchiseHistorySnapShots(id) {
    try {
      let response = await this.get(`${this.entity}/${id.office_id}/franchiseHistory`);
      return response;
    } catch (error) {
      
      throw error;
    }
  }
  static async getFranchiseHistoryBySnapshot(id) {
    try {
      let response = await this.get(`${this.entity}/${id}/franchiseAgreement/loadByFranchiseID`);
      return response;
    } catch (error) {
      
      throw error;
    }
  }
  static async getFranchiseHistorySegmentData(id) {
    try {
      let response = await this.get(`officeFranchiseAgreement/segmentHistory/${id}`);
      return response;
    } catch (error) {
      
      throw error;
    }
  }
  static async getInitialSegmentHistory(id) {
    try {
      let response = await this.get(`officeFranchiseAgreement/segmentHistory/${id}`);
      return response;
    } catch (error) {
      
      throw error;
    }
  }
  static async getInitialFranchiseData(id) {
    try {
      if (typeof id === 'object') {
        let response = await this.get(`${this.entity}/${id.office_id}/franchiseAgreement/loadByFranchiseID`);
        return response;
      } else {
        let response = await this.get(`${this.entity}/${id}/franchiseAgreement/loadByFranchiseID`);
        return response;
      }
    } catch (error) {
      
      throw error;
    }
  }

  static async getFranchise(officeID, params) { return this.get(`office/${officeID}/franchiseAgreement`, params); }

  static async getRegionFranchiseSalesReps(region_id, query = {}) {
    try {
      let response = await this.get(
        `/region/${region_id}/regionalPersonForFranchiseSalesRep?${queryString.stringify({
          sort: 'lastName',
          ...query,
        })}`
      );
      return response;
    } catch (error) {
      
      throw error;
    }
  }

  static async franchiseAgreementSegments(id) {
    try {
      let response = await this.get(`${this.entity}/${id}/franchiseAgreementSegments`);
      return response;
    } catch (error) {
      
      throw error;
    }
  }

  static async officeRankingLabels() {
    try {
      let response = await this.get(`offices/officeRankingLabels`);
      return response;
    } catch (error) {
      
      throw error;
    }
  }

  static async getPersonsTable(id, onlyActive) {//
    try {
      let response = await this.request().get(`${this.entity}/${id}/persons`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      
      throw error;
    }
  }

  static async getOfficeTeamsTable(id, onlyActive) {
    try {
      let response = await this.request().get(`${this.entity}/${id}/teams?only_active=${onlyActive ? 1 : 0}`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      
      throw error;
    }
  }

  static async saveFranchise(data) {
    try {
      let response = await this.put(`officeFranchiseAgreement/${data.ID}`, data);
      return response;
    } catch (error) {
      
      throw error;
    }
  }
  //make this pass filter values not just isActive.
  static async getAllWithFilter(filterTerm) {
    try {
      if (Number.isInteger(filterTerm)) {
        const response = await this.get(`${this.entity}s?filter%5BofficeIsActive%5D%5Bin%5D=${filterTerm}`);
        return response;
      } else {
        const response = await this.get(`${this.entity}s?filter%5BregionName%5D%5Bin%5D=${filterTerm}`);
        return response;
      }
    } catch (error) {
      
      throw error;
    }
  }

  static async getStatus() {
    try {
      const response = await this.get(`offices`);
      return response;
    } catch (error) {
      
      throw error;
    }
  }

  static async getOfficesDropdown(regionID, query = {}) {
    try {
      let response = await this.get(
        `offices/dropdown?${queryString.stringify({
          'filter[regionID]^q]': regionID,
          limit: '0,10',
          sort: '-officeIsActive,name',
          ...query,
        })}`
      );
      // let response = await this.get(`region/${regionID}/officesWithStatus?${queryString.stringify({
      //   'filter[regionID]^q]': regionID,
      //   limit: '0,10',
      //   // sort: 'name',
      //   // only_active: 1,
      //   ...query
      // })}`);
      return response;
    } catch (error) {
      
      throw error;
    }
  }

  static async getRoles(office_id, query) {
    try {
      let response = await this.get(
        `office/${office_id}/roles?${queryString.stringify({
          // "filter[ID][in]": "5,21,9",
          // "filter[remaxEntityCategoryID][eq]": "3",
          // "limit": "0,10",
          // "sort": "roleName",
          ...query,
        })}`
      );
      return response;
    } catch (error) {
      
      throw error;
    }
  }

  // static async getOfficesByRegion(regionId){
  //   // make a request like this v1/office/getAllOfficesInRegion?regionId=29
  //   try {
  //     let response = await this.get(`v1/office/getAllOfficesInRegion?regionId=${regionId}`);
  //     return response;
  //   } catch (error) {
  //     throw error;
  //   }
  // }
}
