import { EbillingService } from '@/services/modules/ebilling.service';

export default {
    namespaced: true,
    state: {
        loadRegionData: true,
        ebillingTableData: {
            list: [],
            totalRows: 0,
            currentPage: 1,
            totalPages: 0,
            rowsPerPage: 0,
        },
        ebillingRowClickIndex: null,
        hideForConfirmation: false,
        ebillingFeeModelData: [],

        allRegionsReportData: {
            list: [],
            totalRows: 0,
            currentPage: 1,
            totalPages: 0,
            rowsPerPage: 0,
        },
        regionsReportData: {
            list: [],
            totalRows: 0,
            currentPage: 1,
            totalPages: 0,
            rowsPerPage: 0,
        },
        allRegionsSelectedDate: {
            month: '',
            year: ''
        },
        currentDate: {
            month: '',
            year: ''
        },
        history: {
            list: [],
            totalRows: 0,
            currentPage: 1,
            totalPages: 0,
            rowsPerPage: 0,
            customHistory: [
                {
                    headerName: 'ID',
                    field: 'id',
                    hide: true,
                },
                {
                    headerName: 'Fee Name',
                    field: 'name',
                    headerTooltip: '',
                    sortable: true,
                    filter: 'agTextColumnFilter',
                },
                {
                    headerName: 'Description',
                    field: 'description',
                    wrapText: true,
                    autoHeight: true,
                    maxWidth: 350,
                    headerTooltip: '',
                    sortable: true,
                    filter: 'agTextColumnFilter',
                },
                {
                    headerName: 'Status',
                    field: 'status',
                    headerTooltip: '',
                    sortable: true,
                    filter: 'agTextColumnFilter',
                },
                {
                    headerName: 'additional Columns',
                    field: '',
                    headerTooltip: '',
                    sortable: true,
                    filter: 'agTextColumnFilter',
                },
            ],
        },
        //Annual Dues - Fee Reliefs and Specials
        feeReliefsAndSpecialsModelData: [],
        yearMonthRegion:{
            month: '',
            year: '',
            regionID: '',
        },
        delayData: '',
        offices: [],
        regions: [],
    },
    mutations: {
        setEbillingTableData(state, data) {
            state.ebillingTableData.list = data.items;

            state.ebillingTableData.totalRows = data.totalItems; //count of all rows
            state.ebillingTableData.currentPage = data.page; //current page
            state.ebillingTableData.totalPages = Math.ceil(Number(data.totalItems) / Number(data.size));
            state.ebillingTableData.rowsPerPage = data.size;
        },
        setEbillingRowClickIndex(state, data) {
            state.ebillingRowClickIndex = data;
        },
        setEbillingHideForConfirmation(state, data){
            state.hideForConfirmation = data;
        },
        setEbillingFeeModelData(state, data) {
            state.ebillingFeeModelData = data;
        },
        setAllRegionReport(state, data) {
            state.allRegionsReportData.list = data;
        },
        setAllRegionsSelectedDate(state, data) {
            state.allRegionsSelectedDate.month = data.month;
            state.allRegionsSelectedDate.year = data.year;
        },
        setCurrentDate(state, data) {
            state.currentDate.month = data.month;
            state.currentDate.year = data.year;
        },
        setRegionReport(state, data) {
            state.regionsReportData.list = data;
        },
        setYearMonthRegion(state, data) {
            state.yearMonthRegion.month = data.month;
            state.yearMonthRegion.year = data.year;
            state.yearMonthRegion.regionID = data.regionID;
        },
        setDelayData(state, data) {
            state.delayData = data;
        },
        setOffices(state, data) {
            state.offices = data;
        },
        setRegions(state, data) {
            state.regions = data;
        }
    },
    actions: {
        async getEbillingTableData({ commit, state }, params) {
            try {
                const res = await EbillingService.getAllEbillingFees(params);
                commit('setEbillingTableData', res.data.data);
                return res;
            } catch (e) {
                throw e;
            }
        },
        async getEbillingFee({ commit, state }, params) {
            try {
                const res = await EbillingService.getEbillingFee(params);
                commit('setEbillingFeeModelData', res.data.data);
                return res;
            } catch (e) {
                throw e;
            }
        },
        async createNewEbillingFee({ commit, state }, params) {
            try {
                const res = await EbillingService.createNewEbillingFee(params);
                return res;
            } catch (e) {
                throw e;
            }
        },
        async updateEbillingFee({ commit, state }, params) {
            try {
                const res = await EbillingService.updateEbillingFee(params);
                return res;
            } catch (e) {
                throw e;
            }
        },
        async removeEbillingFee({ commit, state }, params) {
            try {
                const res = await EbillingService.removeEbillingFee(params);
                return res;
            } catch (e) {
                throw e;
            }
        },
        async getAllRegionReport({ commit, state }, params){
            try {
                const res = await EbillingService.getAllRegionReport(params);
                commit('setAllRegionReport', res.data.data);
                return res;
            } catch (e) {
                throw e;
            }
        },
        async getAllRegionReportInRange({ commit, state }, params){
            try {
                const res = await EbillingService.getAllRegionReportInRange(params);
                commit('setAllRegionReport', res.data.data);
                return res;
            } catch (e) {
                throw e;
            }
        },
        async getPendingApprovals({ commit, state }, params){
            try {
                const res = await EbillingService.getPendingApprovals(params);
                commit('setAllRegionReport', res.data.data);
                return res;
            } catch (e) {
                throw e;
            }
        },
        async updateRegionReportStatus({ commit, state }, params){
            try {
                const res = await EbillingService.updateRegionReportStatus(params);
                return res;
            } catch (e) {
                throw e;
            }
        },
        async getRegionReport({ commit, state }, params){
            try {
                const res = await EbillingService.getRegionReport(params);
                commit('setRegionReport', res.data.data);
                return res;
            } catch (e) {
                throw e;
            }
        },
        async getRegionReportWithExeptions({ commit, state }, params){
            try {
                const res = await EbillingService.getRegionReportWithExeptions(params);
                return res;
            } catch (e) {
                throw e;
            }
        },
        async getRegionMonthlyOverview({ commit, state }, params){
            try {
                const res = await EbillingService.getRegionMonthlyOverview(params);
                return res;
            } catch (e) {
                throw e;
            }
        },
        async transactionUpload({ commit, state }, params) {
            try {
                const res = await EbillingService.transactionUpload(params);
                return res;
            } catch (e) {
                throw e;
            }
        },
        async monthlyReportingUpdateOne({ commit, state }, params) {
            try {
                const res = await EbillingService.monthlyReportingUpdateOne(params);
                return res;
            } catch (e) {
                throw e;
            }
        },
        async monthlyReportingUpdateOneChild({ commit, state }, params) {
            try {
                const res = await EbillingService.monthlyReportingUpdateOneChild(params);
                return res;
            } catch (e) {
                throw e;
            }
        },
        async getFees({ commit, state }, params) {
            try {
                const res = await EbillingService.getFees(params);
                return res;
            } catch (e) {
                throw e;
            }
        },
    },
};
