import { BaseService } from '../base.service';
import { ResponseWrapper, ErrorWrapper } from '../util';
export class ExceptionService extends BaseService {
    static get entity() {
        return 'exception';
    }
    static async getAll(opts) {
        try {
            let response = await this.get(`ebilling#exception/franchise-agreement`);
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.status;
            throw new ErrorWrapper(error, message);
        }
    }
    static async findByOfficeId(id) {
        try {
            let response = await this.get(`ebilling#office/${id}`);
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.status;
            throw new ErrorWrapper(error, message);
        }
    }
    static async getOfficeListByRegionID(params) {
        try {
            let response = await this.get(`ebilling#exception/office/office-list-by-region/${params.regionID}?addOfficeId=${params.officeID}`);
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.status;
            throw new ErrorWrapper(error, message);
        }
    }
    static async createOne(params) {
        try {
            let response = await this.post(`ebilling#/exception/franchise-agreement/createOne`, params);
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.status;
            throw new ErrorWrapper(error, message);
        }
    }
    static async updateOne(params) {
        try {
            let response = await this.put(`ebilling#/exception/franchise-agreement/updateOne/${params.id}`, params.payload);
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.status;
            throw new ErrorWrapper(error, message);
        }
    }

    static async findOneByFranchiseAgreementID(id) {
        try {
            let response = await this.get(`ebilling#/exception/franchise-agreement/findByAgreementId/${id}`, id);
            return new ResponseWrapper(response, response.data);
        } catch (error) {
            const message = error.response.data ? error.response.data.error : error.response.status;
            throw new ErrorWrapper(error, message);
        }
    }
}
