import { ExceptionService } from '@/services/modules/exception.service';

export default {
  namespaced: true,
  state: {
    currentCountry: {},
    currentCountryByKey: [],
    countryList: [],
    phoneCodes: [],
    languages: [],
    countryStates: {},
    loadingPhoneCodes: false,
    loadingCountryStates: false,
  },
  actions: {
    async getAll({ commit }, opts) {
      try {
      const res = await ExceptionService.getAll(opts);
      return res
  } catch (e) {
      throw e;
    }
  },
    async findByOfficeId({ commit }, id) {
        try {
        const res = await ExceptionService.findByOfficeId(id);
        return res
    } catch (e) {
        throw e;
      }
    },

    async getOfficeListByRegionID({ commit }, id) {
        try {
        const res = await ExceptionService.getOfficeListByRegionID(id);
        return res
    } catch (e) {
        throw e;
      }
    },
    async getOfficeListByRegionIDEdit({ commit }, params) {
        try {
        const res = await ExceptionService.getOfficeListByRegionID(params);
        return res
    } catch (e) {
        throw e;
      }
    },

    async createOne({ commit }, payload) {
        try {
        const res = await ExceptionService.createOne(payload);
        return res
    } catch (e) {
        throw e;
      }
    },

    async updateOne({ commit }, payload) {
        try {
        const res = await ExceptionService.updateOne(payload);
        return res
    } catch (e) {
        throw e;
      }
    },

    async findOneByFranchiseAgreementID({ commit }, id) {
        try {
        const res = await ExceptionService.findOneByFranchiseAgreementID(id);
        return res
    } catch (e) {
        throw e;
      }
    },
  },
};
