import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';
import store from '../store';
/**
 * Return message for HTTP status code
 * @param {number} status - HTTP status code
 * @returns {string} Message of network operation
 */
function _getStatusMessage(status) {
  let message = '';
  switch (status) {
    case 200:
      message = 'All done. Request successfully executed';
      break;
    case 201:
      message = 'Successfully created/updated';
      break;
    case 400:
      message = 'Bad Request';
      break;
    case 401:
      message = 'Need auth';
      break;
    case 404:
      message = 'Not found';
      break;
    case 503:
      message = 'Service unavailable. Try again later';
      break;
    default:
      message = 'Service unavailable. Try again later';
      break;
  }
  return message;
}

function _getStatusType(status) {
  let message = '';
  switch (status) {
    case 200:
      message = 'success';
      break;
    case 201:
      message = 'success';
      break;
    case 400:
      message = 'warning';
      break;
    case 401:
      message = 'warning';
      break;
    case 404:
      message = 'error';
      break;
    case 503:
      message = 'error';
      break;
    default:
      message = 'error';
      break;
  }
  return message;
}

function _getResponseErrorMessage(error) {
  if (error.response && error.response.data) return error.response.data.message;
  if (error.response && error.response.statusText) return error.response.statusText;
  return error.message === 'Network Error' ? 'Oops! Network Error. Try again later' : error.message;
}

function _getResponseSuccessMessage(message, defaultMessage = 'Success') {
  if (message && typeof message === 'string') return message;
  return defaultMessage;
}

/**
 * Create instance, which represent response object
 * @param {Object} [data] - custom data
 * @param {Object} [response] - axios response object
 * @param {String} [message] - custom message to display
 */
export class ResponseWrapper {
  constructor(response, message, model = null) {
    this.data = response.data;
    this.success = response.data.success;
    this.status = response.status;
    this.statusMessage = _getStatusMessage(response.status);
    this.message = _getResponseSuccessMessage(message, 'Successfully inserted / updated row');
    this.type = _getStatusType(response.status);
    if (response.config.method === 'post' || response.config.method === 'put') {
      if (model === 'notification') {
        //Toast messages are disabled for read notifications on other environments
        if (process.env.NODE_ENV === 'development') {
          store.commit(`toast/NEW`, { message: this.message, type: 'success' });
        }
      } else {
        store.commit(`toast/NEW`, { message: this.message, type: 'success' });
      }
    } else if (response.config.method === 'delete') {
      store.commit(`toast/NEW`, { message: 'Successfully Deleted Row', type: 'success' });
    }
  }
}

/**
 * Create instance, which represent error object
 * @param {Object} [error] - axios error object
 * @param {String} [message] - custom message to display
 */
export class ErrorWrapper extends Error {
  constructor(error, message) {
    super();
    this.success = error.response ? error.response.data.success : false;
    this.meta = error.response ? error.response.data.meta : false;
    this.code = error.response ? error.response.data.code : false;
    this.status = error.response ? error.response.status : false;
    this.statusMessage = _getStatusMessage(this.status);
    this.message = message || _getResponseErrorMessage(error);
    this.type = _getStatusType(error.status);
    
    const shouldAuthenticate = !!JSON.stringify(error.response || '{}').match('"code":4004');
    store.commit('auth/setShouldAuthenticated', shouldAuthenticate);
    if (!shouldAuthenticate) {
      if(error.response.data.message){
        let message = error.response.data.message;
        if (!Array.isArray(message)) {
          message = [message];
          store.commit('dom/setErrorList', message);
        }else{
        store.commit('dom/setErrorList', error.response.data.message);
        }
        // console.log(error.response);
        store.commit(`toast/NEW`, { message: this.statusMessage, type: this.type });
      }else{
        let message = this.message;
        if (!Array.isArray(message)) {
          message = [message];
          store.commit('dom/setErrorList', message);
        }else{
            store.commit('dom/setErrorList', this.message);
        }
        store.commit(`toast/NEW`, { message: this.statusMessage, type: this.type });
      }

    }
  }
}

/**
 * Uses to clear request data before send it
 * Client shouldn't change entity id
 * @param data
 * @return {{}}
 */
export function clearData(data) {
  const result = {};
  forEach(data, (item, propName) => {
    if (isArray(item) && item.length) {
      result[propName] = item;
    }
    if (!isArray(item) && item && propName !== 'id') {
      result[propName] = item;
    }
  });
  return result;
}
